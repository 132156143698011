'use strict';

angular.module('enervexSalesappApp').controller('AccountLocationsCtrl', function($scope, $stateParams, $state, AccountLocation, Account, State, Contact) {
	$scope.accountId = $stateParams.accountId;
	AccountLocation.query({
		accountId: $stateParams.accountId
	}).$promise.then(function(accountLocations){
		$scope.accountLocations = accountLocations;
	});
	Account.get({
		id: $stateParams.accountId
	}).$promise.then(function(res){
		$scope.account = res;
	});
	State.query({limit: 300}).$promise.then(function(states){
		$scope.states = states;
	});
	Contact.query({
		accountId: $stateParams.accountId
	}).$promise.then(function(contacts){
		$scope.contacts = contacts;
	});
	$scope.showLocationFormArea = function(){
		$state.go('account-location', {
			accountId: $scope.accountId,
			accountLocationId: 'new'
		}, {
			reload: true,
			inherit: false
		})
	};
});
